/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */

import _ from 'lodash'
import camelcaseKeys from 'camelcase-keys'
import React, {
  useState, useEffect, useRef,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useUser } from 'react-omnitech-api'
import CrossIcon from '../../assets/icons/icon_cross.svg'
import ModalFilter from './components/modal-filter/modal-filter-view'
import DesktopFilters from './components/desktop-filters/desktop-filter'
import CategoryMenu from './components/category-menu'
import FilterIcon from '../../assets/icons/icon_filter.svg'
import SortIcon from '../../assets/icons/icon_sort.svg'
import ArrowDownIcon from '../../assets/icons/icon_right_arrow.svg'
import placeholderImage from '../../assets/images/icon_image_placeholder.png'

import Breadcrumb from '../../ui/breadcrumb'
import Button from '../../ui/button'
import ContentGroup from '../../ui/content-group'
import DineInBanner from '../../ui/dine-in-banner'
import Layout from '../../ui/layout'
import ModalCompare from '../../ui/modal-compare'
import Pagination from '../../ui/pagination'
import ProductCard from '../../ui/product-card'
import ProductCardPlaceholder from '../../ui/product-card-placeholder'
import SEO from '../../ui/seo'
import Title from '../../ui/title-detail'
import useCustomerRankSettings from '../../hook/use-customer-rank-settings'
import { useThemeConfig } from '../../hook/use-theme-config'
import { combineClassNames } from '../../helpers'
import useStyles from './products-style'

function ProductsView(props) {
  const { i18n, t } = useTranslation()
  const {
    availableFilters,
    brandOptions,
    breadcrumb,
    category,
    colorMasterOptions,
    colorMasterOptionsPlaceholder,
    compareData,
    currency,
    distinct,
    enableComparisonEcom,
    categoryOptions,
    filterOptions,
    goToCompareProducts,
    hasMoreThan,
    hasSizeMasterMore,
    inventoryStoreCode,
    initLoading,
    isLoading,
    isOpen,
    isResetFilterActive,
    loadingBrands,
    loadingNextSizeMaster,
    maxNumberComparisonEcom,
    onAddToCompare,
    onBrandChange,
    onCategoryChange,
    onClearCompare,
    onClickTrackEvent,
    onColorOptionChange,
    onContinueShopping,
    onFetchSizeMasterNextPage,
    onPageClick,
    onPageSizeUpdate,
    onProductClick,
    onResetFilter,
    onSizeOptionChange,
    onSortByUpdate,
    pageSizeOptions,
    pageTitle,
    pagination,
    plpContentGroupCode,
    productImpressions,
    seoDescription,
    seoMeta,
    seoTitle,
    showBreadCrumb,
    siblings,
    sizeMasterOptions,
    sizeMasterOptionsPlaceholder,
    skus,
    sortByOptions,
    sortByOptionsPlaceholder,
  } = props
  const titleRef = useRef()
  const { default: defaultRank } = useCustomerRankSettings()
  const {
    loyaltyInformation,
  } = useUser()
  const { getConfig } = useThemeConfig()
  // enableBreadCrumb: enable by default;
  const enableBreadCrumb = getConfig('config.pages.products.enableBreadCrumb', true) !== false
  const enableCategoryBanner = getConfig('config.pages.products.enableCategoryBanner', true) !== false
  const enableQuickAddItem = getConfig('config.enableQuickAddItem', false)
  // const [isSelectFilters, setIsSelectFilters] = useState(false)
  const [isSelectBySort, setIsSelectBySort] = useState(false)
  const [typeFilterOpen, setTypeFilterOpen] = useState('')
  const [hasResults] = useState(false)
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
  const [brandsFiltered, setBrandsFiltered] = useState(brandOptions)
  const [hideFilters] = useState(false)

  // set values in special object as sortBy is
  // it comes from this issues in react-select https://github.com/JedWatson/react-select/issues/2674
  const isSearch = !_.isEmpty(_.get(filterOptions, 'q'))
  const selectedSortBy = _.get(filterOptions, 'sortBy')
  const selectedOptionSortBy = _.find(sortByOptions, { value: selectedSortBy })
  const categoryImage = _.get(category, 'image.versions.ecomThumbMedium')
  const RenderImage = enableCategoryBanner && categoryImage
    ? <img src={categoryImage} alt={_.get(category, 'name')} />
    : null
  const totalPages = _.get(pagination, 'totalPages', 0)
  const resultColorMasterOptions = colorMasterOptions.length > 1
    ? colorMasterOptions
    : _.get(colorMasterOptions, '[0].options', [])
  const resultSizeMasterOptions = sizeMasterOptions.length > 1
    ? sizeMasterOptions
    : _.get(sizeMasterOptions, '[0].options', [])

  const currentRank = _.get(loyaltyInformation, 'currentCustomerRank.code', defaultRank)

  const handlePageClick = (page) => {
    // pagination component page number start from 0
    const selectedPage = page.selected + 1
    if (titleRef && (_.has(filterOptions, 'page') || selectedPage > 1)) {
      window.scrollTo(0, _.get(titleRef, 'current.offsetTop', 0))
    }
    onPageClick(selectedPage)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'f') {
      setIsFilterModalOpen(!isFilterModalOpen)
    }
  }

  const handelOpenMenu = (type) => {
    setTypeFilterOpen(type)
    setIsFilterModalOpen(!isFilterModalOpen)
  }

  const renderProduct = (sku, index) => {
    const siblingsProductCodes = _.get(sku, 'product.meta.siblingsProductCodes', [])
    const thisSiblings = _.filter(siblings, (sibling) => (
      _.includes(siblingsProductCodes, _.get(sibling, 'product.code', '////'))
    ))
    const minimumBundlePrice = _.get(sku, 'meta.priceDisplayMode') === 'minimum_bundle_price'
      ? _.get(sku, 'meta.minimumBundlePrice', null)
      : null
    const haveSiblings = !_.isEmpty(thisSiblings)
    const priceSuffix = (haveSiblings || _.isNumber(minimumBundlePrice)) && t('screens.product.price.up')
    const lowestSellPriceSibling = _.reduce(thisSiblings, (result, sibling) => {
      if (
        _.toNumber(_.get(result, 'sellPrice', 0))
        > _.toNumber(_.get(sibling, 'sellPrice', 0))
      ) {
        result = sibling
      }
      return result
    }, _.first(thisSiblings))
    let displaySellPrice = _.get(sku, 'colorOption.sellPrice', null)
    if (haveSiblings) displaySellPrice = _.get(lowestSellPriceSibling, 'sellPrice')
    if (_.isNumber(minimumBundlePrice)) displaySellPrice = minimumBundlePrice
    let displayOriginalPrice = _.get(sku, 'colorOption.originalPrice', null)
    if (haveSiblings) displayOriginalPrice = _.get(lowestSellPriceSibling, 'originalPrice')
    if (_.isNumber(minimumBundlePrice)) displayOriginalPrice = null
    let displayMemberPrice = _.get(sku, `meta.memberPrice.${_.camelCase(currentRank)}`)
    if (haveSiblings) displayMemberPrice = _.get(lowestSellPriceSibling, `meta.memberPrice.${_.camelCase(currentRank)}`)
    if (_.isNumber(minimumBundlePrice)) displayMemberPrice = null
    const stockLevel = distinct === 'p' ? _.get(sku, 'product.stockLevel') : _.get(sku, 'colorOption.stockLevel')
    return (
      <ProductCard
        key={`product-card-${_.get(sku, 'id', index)}`}
        className={styles.productCards}
        colorOptionVariantType={_.get(sku, 'product.colorOptionVariantType', {})}
        productPage
        imageUrl={_.get(sku, 'colorOption.images.0.versions.webLarge') || _.get(sku, 'colorOption.defaultImage.versions.webLarge')}
        title={_.get(sku, 'product.title')}
        detailsPlainText={_.get(sku, 'product.detailsPlainText')}
        colorName={_.get(sku, 'colorOption.name')}
        sellPrice={displaySellPrice}
        originalPrice={displayOriginalPrice}
        memberPrice={displayMemberPrice}
        priceSuffix={priceSuffix}
        activeCustomLabels={_.get(sku, 'colorOption.activeCustomLabels')}
        colorOptionId={_.get(sku, 'colorOption.id')}
        favourite={_.get(sku, 'colorOption.favourite')}
        productId={_.get(sku, 'product.id')}
        url={_.get(sku, 'product.canonicalHref')}
        stockLevel={stockLevel}
        sku={sku}
        distinct={distinct}
        inventoryStoreCode={inventoryStoreCode}
        compareData={compareData}
        onAddToCompare={onAddToCompare}
        hasMoreThan={hasMoreThan}
        enableComparisonEcom={enableComparisonEcom}
        onClickTrackEvent={onClickTrackEvent}
        onClick={enableQuickAddItem ? onProductClick : null}
        index={index + 1}
      />
    )
  }

  function handleColorOptionChange(event) {
    const value = _.map(event, 'value')
    onColorOptionChange(value)
  }

  function handleSizeOptionChange(event) {
    const value = _.map(event, 'value')
    onSizeOptionChange(value)
  }

  function handleBrandOptionChange(event) {
    const value = _.map(event, 'value')
    onBrandChange(value)
  }

  function handleCategoryChange(event) {
    const value = _.get(event, 'value')
    onCategoryChange(value)
  }

  function handlePageSizeOptionChange(event) {
    const value = _.get(event, 'value')
    onPageSizeUpdate(value)
  }

  function handleSortByChange(event) {
    const value = _.get(event, 'value')
    onSortByUpdate(value)
  }

  useEffect(() => {
    setIsSelectBySort(_.get(filterOptions, 'sortBy'))
  }, [filterOptions])

  useEffect(() => {
    // setHideFilters(!isSelectFilters && skus.length === 0)
    // setHideFilters(!isSelectFilters)
  }, [filterOptions, skus])

  useEffect(() => {
    setBrandsFiltered(_.sortBy(brandOptions, ['label'], ['asc']))
  }, [brandOptions])

  const styles = useStyles({ isSelectBySort, isSelectFilters: isResetFilterActive })
  const availableOptionsFiltersMobile = _.get(availableFilters, 'mobileView', {})
  const availableFiltersMobile = _.get(availableOptionsFiltersMobile, 'left')

  const modalProps = {
    availableFiltersMobile,
    brandOptions: brandsFiltered,
    categoryOptions,
    colorMasterOptions: resultColorMasterOptions,
    colorMasterOptionsPlaceholder,
    filterOptions,
    onFetchSizeMasterNextPage,
    hasSizeMasterMore,
    handelOpenMenu,
    i18n,
    isFilterModalOpen,
    pageSizeOptions,
    selectedOptionSortBy,
    sizeMasterOptions,
    sizeMasterOptionsPlaceholder,
    sortByOptions,
    typeFilterOpen,
    onBrandChange,
    onCategoryChange,
    onColorOptionChange,
    onPageSizeUpdate,
    onSizeOptionChange,
    onSortByUpdate,
  }
  const desktopFilterProps = {
    availableFilters,
    brandsFiltered,
    colorMasterOptionsPlaceholder,
    categoryOptions,
    filterOptions,
    hideFilters,
    loadingBrands,
    loadingNextSizeMaster,
    loadingSkus: isLoading,
    pageSizeOptions,
    resultColorMasterOptions,
    resultSizeMasterOptions,
    selectedOptionSortBy,
    sizeMasterOptionsPlaceholder,
    sortByOptionsPlaceholder,
    sortByOptions,
    styles,
    skus,
    t,
    handlePageSizeOptionChange,
    handleColorOptionChange,
    handleSizeOptionChange,
    handleBrandOptionChange,
    handleCategoryChange,
    handleSortByChange,
    onFetchSizeMasterNextPage,
  }
  const placeholder = getConfig('config.placeholderImage', placeholderImage)
  const enableCategoryMenu = getConfig('config.pages.products.enableCategoryMenu', false)
  return (
    <Layout
      HeaderBottomComponent={
        (
          <>
            <DineInBanner />
            {
              enableCategoryMenu
              && !isSearch
              && (
                <div className={styles.categoryMenuContainer}>
                  <CategoryMenu
                    {...camelcaseKeys(filterOptions)}
                    onCategoryChange={onCategoryChange}
                    defaultTitle={pageTitle}
                  />
                </div>
              )
            }
          </>
        )
      }
    >
      {/* passing page specific data for SEO */}
      {/* TODO: add seo description and meta */}
      <SEO
        description={seoDescription}
        title={seoTitle}
        meta={seoMeta}
        // productImpressions
        linkedData={[{
          '@context': 'http://schema.org',
          '@type': 'ItemList',
          name: seoTitle,
          description: seoDescription,
          itemListElement: _.map(productImpressions, (pi) => ({
            '@type': 'Product',
            name: pi.name,
            // description: pi.description,
            image: pi.image || placeholder,
            url: pi.url,
            brand: _.isEmpty(pi.brand) ? undefined : {
              '@type': 'Brand',
              name: pi.brand,
            },
            offers: {
              '@type': 'Offer',
              availability: _.isNull(pi.stockLevel) || pi.stockLevel > 0
                ? 'https://schema.org/InStock'
                : 'https://schema.org/SoldOut',
              price: pi.price,
              priceCurrency: _.get(currency, 'code'),
            },
          })),
        }]}
      />
      {
        showBreadCrumb && enableBreadCrumb && breadcrumb.length > 1
          ? <Breadcrumb classN={styles.breadcrumb} links={breadcrumb} />
          : enableBreadCrumb && <div className={styles.emptyBreadCrumb} />
      }
      <div className={styles.containerFluid}>
        <div className={styles.container}>
          <>
            {
            plpContentGroupCode && (
              <ContentGroup
                // FL: key is needed to make sure the category banner is rendered correctly
                key={`plp_content_group_code_${plpContentGroupCode}`}
                cat={category}
                fallback={RenderImage}
                code={plpContentGroupCode}
                template="ContentGroup"
              />
            )
            }
            {
              !enableCategoryMenu
              && (
                <div className={styles.containerTitle} id="titleRef" ref={titleRef}>
                  {/* TODO: use title from department or categories */}
                  <Title text={pageTitle} className={styles.title} />
                </div>
              )
            }
            {/* mobile filter */}
            <div className={styles.containerFiltersMobile}>
              <div className={styles.filterBoxMobile}>
                {
                  !_.isEmpty(availableOptionsFiltersMobile.left) && (
                    <div
                      tabIndex={0}
                      onClick={() => handelOpenMenu('filter')}
                      role="button"
                      onKeyDown={(e) => handleKeyPress(e)}
                      className={styles.boxFilter}
                    >
                      <img
                        src={FilterIcon}
                        alt=""
                      />
                      <span>{t('screens.products.titleFilters')}</span>
                    </div>
                  )
                }
                {
                  _.map(availableOptionsFiltersMobile.right, (filter) => {
                    let elem;
                    switch (true) {
                      case filter === 'sortBy':
                        elem = (
                          <div
                            className={styles.boxSortBy}
                            tabIndex={-1}
                            onClick={() => handelOpenMenu('sortBy')}
                            role="button"
                            key={filter}
                          >
                            <img src={SortIcon} alt="" />
                            <span>{t('screens.products.sort')}</span>
                          </div>
                        )
                        break;
                      case filter === 'view':
                        elem = (
                          <div
                            className={styles.filterByPageStyle}
                            tabIndex={-2}
                            onClick={() => handelOpenMenu('page')}
                            role="button"
                            key={filter}
                            onKeyDown={(e) => handleKeyPress(e)}
                          >
                            <span>
                              {t('screens.products.view')}
                              :
                            </span>
                            <p>
                              {_.get(filterOptions, 'pageSize')}
                              <img src={ArrowDownIcon} alt="" />
                            </p>
                          </div>
                        )
                        break;
                      default:
                        break;
                    }
                    return elem
                  })
                }
              </div>
              <div className={styles.boxResetBtnStyle}>
                {
                  isResetFilterActive && (
                    <Button
                      className={styles.resetBtnStyle}
                      text="Reset"
                      iconImage={CrossIcon}
                      onClick={onResetFilter}
                    />

                  )
                }
              </div>
            </div>
            {/* desktop filter */}
            <div className={styles.containerFilters}>
              <DesktopFilters {...desktopFilterProps} />
              {/*
              TODO: check FilterOptions, only show reset button
              when any filter options selected
            */}
              <div className={styles.boxResetBtnStyle}>
                {
                  isResetFilterActive
                    && (
                      <Button
                        className={styles.resetBtnStyle}
                        text="Reset"
                        iconImage={CrossIcon}
                        onClick={onResetFilter}
                      />
                    )
                }
              </div>
            </div>
            {hasResults && <div className={styles.resultStyle}>{t('screens.products.found', { items: 1, product: ['Shirt'] })}</div>}
            {(isLoading || initLoading) ? (
              <div className={styles.containerProducts}>
                {
                  _.times(_.get(filterOptions, 'pageSize', _.get(pageSizeOptions, '0.value', 8)), (index) => (
                    <div key={`product-card-placeholder-${index}`} className={styles.productCards}>
                      <ProductCardPlaceholder />
                    </div>
                  ))
                }
              </div>
            )
              : (
                <>
                  {
                    totalPages > 1
                    && (
                      <div
                        className={combineClassNames([styles.pagination, styles.paginationTop])}
                      >
                        <Pagination
                          pagination={pagination}
                          handlePageClick={(e) => handlePageClick(e)}
                        />
                      </div>
                    )
                  }
                  <div className={styles.containerProducts}>
                    {skus.length >= 1 ? skus.map(renderProduct) : (
                      <div className={styles.noResultStyle}>
                        <p>{t('screens.products.empty')}</p>
                        <Button
                          className={styles.buttonContinue}
                          dark
                          text={t('screens.products.button')}
                          onClick={onContinueShopping}
                        />
                      </div>
                    )}
                  </div>
                  {
                    totalPages > 1 && (
                      <div
                        className={combineClassNames([styles.pagination, styles.paginationBottom])}
                      >
                        <Pagination
                          pagination={pagination}
                          handlePageClick={(e) => handlePageClick(e)}
                        />
                      </div>
                    )
                  }
                </>
              )}
          </>
        </div>
      </div>
      <ModalFilter {...modalProps} />
      {
        enableComparisonEcom
        && (
        <ModalCompare
          compareData={compareData}
          onAddToCompare={onAddToCompare}
          goToCompareProducts={goToCompareProducts}
          t={t}
          isOpen={isOpen}
          onClearCompare={onClearCompare}
          maxNumberComparisonEcom={maxNumberComparisonEcom}
        />
        )
      }
    </Layout>
  )
}

ProductsView.propTypes = {
  skus: PropTypes.array,
}

ProductsView.defaultProps = {
  skus: [],
}

export default ProductsView
