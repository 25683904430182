/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React, {
  useEffect,
  useState,
  forwardRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import useStyles from './wrapper-select-style'
import { Button, Spinner } from '../../../../ui'
import ArrowLeft from '../../../../assets/icons/icon_left_arrow.svg'
import CheckboxFilter from '../checkbox-filter/checkbox-filter-view'
import HeaderSelect from '../header-select/header-select-view'

const WrapperSelectView = ({
  values = [],
  isOptionOpen,
  handelOpenOptions,
  selectedByType,
  title,
  type,
  setIsOptionOpen,
  onCategoryChange,
  onColorOptionChange,
  onSizeOptionChange,
  onBrandChange,
  handelOpenMenu,
  onFetchSizeMasterNextPage,
  hasSizeMasterMore,
}, ref) => {
  const {
    wrapperRef,
    bodyRef,
  } = ref
  const { t } = useTranslation()
  const hasOptions = values.filter((v) => _.has(v, 'options'))
  const style = useStyles({
    isOptionOpen,
    type,
    hasOptions,
  })
  const [selecteds, setSelecteds] = useState([])
  function removeItemFromArr(arr, item) {
    return arr.filter((e) => e !== item);
  }
  const selectOnChange = (e) => {
    const { value } = e.target;
    const availableValues = _.intersection(
      _.flatMap(values, ({ value: val, options }) => {
        if (!_.isEmpty(val)) {
          return val
        }
        if (!_.isEmpty(options)) {
          return _.map(options, 'value')
        }
      }),
      selecteds,
    )
    if (!_.includes(availableValues, value)) {
      setSelecteds([...availableValues, value])
    } else {
      const newValues = removeItemFromArr(availableValues, value)
      setSelecteds(newValues)
    }
  }

  const onSelect = (typeBy) => {
    switch (typeBy) {
      case 'color':
        onColorOptionChange(selecteds)
        break;
      case 'size':
        onSizeOptionChange(selecteds)
        break;
      case 'category':
        onCategoryChange(selecteds)
        break;
      case 'brand':
        onBrandChange(selecteds)
        break;
      default:
        break;
    }
    setIsOptionOpen(false)
    handelOpenMenu()
  }

  useEffect(() => {
    setSelecteds([...selectedByType])
  }, [selectedByType])

  return (
    <div className={style.wrapper} ref={wrapperRef}>
      <div className={style.headWrapper}>
        <Button
          inline
          iconImage={ArrowLeft}
          onClick={handelOpenOptions}
        />
        <h5>{title}</h5>
        <Button inline text={t('screens.products.apply')} onClick={() => onSelect(type)} />
      </div>

      <div className={style.bodyWrapper} ref={bodyRef}>
        <ul>
          { type === 'size' ? (
            // RJulia 2020/05/13 temporaly solution until make pagination for all filters
            !_.isEmpty(values) ? (
              <div className={style.contentWrapper}>
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={onFetchSizeMasterNextPage}
                  hasMore={hasSizeMasterMore}
                  loader={<div className="loader" key={0}><Spinner size="small" /></div>}
                  // eslint-disable-next-line react/jsx-boolean-value
                  useWindow={false}
                >
                  {values.map((option) => (
                    <HeaderSelect
                      key={option.id}
                      option={option}
                      selecteds={selecteds}
                      handleOnChange={selectOnChange}
                    />
                  ))}
                </InfiniteScroll>
              </div>
            ) : (
              <div className={style.noOption}>
                <p>{t('ui.menuMobile.noOptionsMessage')}</p>

              </div>
            )
          ) : (
            !_.isEmpty(values) ? values.map((option) => {
              if (!_.isEmpty(hasOptions)) {
                return (
                  <HeaderSelect
                    key={option.id}
                    option={option}
                    selecteds={selecteds}
                    handleOnChange={selectOnChange}
                  />
                )
              }
              return (
                <CheckboxFilter
                  option={option}
                  key={option.id}
                  selecteds={selecteds}
                  handleOnChange={selectOnChange}
                />
              )
            })
              : (
                <div className={style.noOption}>
                  <p>{t('ui.menuMobile.noOptionsMessage')}</p>

                </div>
              )
          )}

        </ul>
      </div>
    </div>
  )
}

export default forwardRef(WrapperSelectView)
