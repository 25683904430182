import _ from 'lodash'
import React, { useRef } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useTranslation } from 'react-i18next'
import Button from '../../../../ui/button'
import Link from '../../../../ui/link'
import combineClassNames from '../../../../helpers/combineClassNames'
import IconLeftArrow from '../../../../assets/icons/icon_left_arrow.inline.svg'
import useStyles from './category-menu-style'

const CategoryMenuView = (props) => {
  const {
    categories,
    defaultTitle,
    selectedCategory,
    parentCategory,
    onCategoryChange,
    loading,
  } = props
  const { t } = useTranslation()
  const randomPlaceholderWidth = useRef(
    _.times(8, () => (
      _.floor(
        _.random(6, 10, true),
        2,
      )
    )),
  )
  const styles = useStyles()
  return (
    <div className={styles.container}>
      <div className={styles.centerContent}>
        <Link
          to="/categories/"
          gatsbyLink
          className={styles.backToCategories}
        >
          <IconLeftArrow />
          {t('screens.products.categoryMenu.back')}
        </Link>
        {
          !loading && (
            <h1 className={styles.parentCategoryName}>{_.get(parentCategory, 'name', defaultTitle || '')}</h1>
          )
        }
        {
          (loading || _.size(categories) > 1) && (
            <div
              className={
                combineClassNames([
                  styles.menuContainer,
                  loading && styles.menuContainerLoading,
                ])
              }
            >
              <ScrollContainer
                vertical={false}
                className={styles.menu}
                component="ul"
              >
                {
                  loading
                    ? (
                      _.times(8, (index) => (
                        <li
                          key={`category_menu_item_placeholder_${index}`}
                          className={
                            combineClassNames([
                              styles.menuItem,
                              styles.menuItemPlaceholder,
                            ])
                          }
                        >
                          <span
                            style={{
                              width: `${randomPlaceholderWidth.current[index]}rem`,
                            }}
                            className={
                              combineClassNames([
                                styles.menuItemInner,
                                styles.menuItemPlaceholderInner,
                              ])
                            }
                          />
                        </li>
                      ))
                    )
                    : (
                      <>
                        {
                          !_.isEmpty(parentCategory)
                          && (
                            <li
                              className={
                                combineClassNames([
                                  styles.menuItem,
                                  selectedCategory === _.get(parentCategory, 'code', '////') && styles.menuItemActive,
                                ])
                              }
                            >
                              <Button
                                className={
                                  combineClassNames([
                                    styles.menuItemInner,
                                    selectedCategory === _.get(parentCategory, 'code', '////')
                                    && styles.menuItemInnerActive,
                                  ])
                                }
                                inline
                                onClick={() => onCategoryChange(_.get(parentCategory, 'code'))}
                              >
                                {t('screens.products.categoryMenu.all')}
                              </Button>
                            </li>
                          )
                        }
                        {
                          _.map(categories, ({ code, name }) => (
                            <li
                              key={`category_menu_item_${code}`}
                              className={
                                combineClassNames([
                                  styles.menuItem,
                                  selectedCategory === code && styles.menuItemActive,
                                ])
                              }
                            >
                              <Button
                                className={
                                  combineClassNames([
                                    styles.menuItemInner,
                                    selectedCategory === code
                                    && styles.menuItemInnerActive,
                                  ])
                                }
                                inline
                                onClick={() => onCategoryChange(code)}
                              >
                                {name}
                              </Button>
                            </li>
                          ))
                        }
                      </>
                    )
                }
              </ScrollContainer>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default CategoryMenuView
