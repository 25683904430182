/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import flow from 'lodash/fp/flow'
import find from 'lodash/fp/find'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr';
import last from 'lodash/fp/last'
import filter from 'lodash/fp/filter'
import camelcaseKeys from 'camelcase-keys'

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  useBrands,
  useColorMasters,
  useCurrencies,
  useSizeMasters,
  useSkus,
  useSystemSettings,
  useDepartments,
  useCategories,
  useProductGroups,
} from 'react-omnitech-api'
import URI from 'urijs'
import {
  useAnalytics,
  useCart,
  useLink,
  useOrderMethod,
  useProductQuickAddModal,
  useCompare,
  useSku,
  useThemeConfig,
  useAlert,
} from '../../hook'
import {
  getBannerName,
  getProductListFilterOptionsFromUrl,
  getTopLevelCategoryCode,
  isBrowser,
  isNullOrUndefined,
  transformFilterBrands,
  transformFilterCategories,
  transformFilterColorOptions,
  transformFilterPageSize,
  transformFilterSizeOptions,
  transformFilterSortBy,
} from '../../helpers'
import ProductsView from './products-view'

function ProductsController(props) {
  const { location, pageContext } = props

  // prepare hook
  const { getProductParams, trackEvent } = useAnalytics()
  const alert = useAlert()
  const { navigate } = useLink()
  const { fetchBrandsAll } = useBrands()
  const { fetchColorMasters } = useColorMasters()
  const { fetchSizeMasters } = useSizeMasters()
  const { fetchSkus, fetchSkusAll } = useSkus()
  const { getSystemSetting } = useSystemSettings()
  const { departments } = useDepartments()
  const { categories } = useCategories()
  const { fetchProductGroupByCode, fetchProductGroup } = useProductGroups()
  const { i18n, t } = useTranslation()
  const {
    compareData,
    goToCompareProducts,
    addItemToCompare,
    hasMoreThan,
    isOpen,
    clearItemToCompare,
    enableComparisonEcom,
    maxNumberComparisonEcom,
  } = useCompare()
  const {
    store,
    orderMethod,
    selectableOrderMethod,
  } = useOrderMethod()
  const {
    totalItems,
    inventoryStoreCode,
    // cart,
    initCart,
  } = useCart()
  const {
    getMetaMenuFilterParams,
    getMetaMenuCodeFilterParamsAsync,
  } = useSku()
  const {
    open: openProductQuickAddModal,
  } = useProductQuickAddModal()
  const { currencies } = useCurrencies()
  const { getConfig } = useThemeConfig()

  const currentLocale = i18n.language
  const currency = _.find(currencies, { isBaseCurrency: true })

  // dependence setting
  // default page size options when it is not available in system setting
  const defaultpageSizeOptions = {
    pageSizes: [8, 12, 24, 36, 48, 60],
    default: 8,
  }

  const fetchSkuApiTimer = useRef(null)

  const pageSizeOptions = getSystemSetting('product.page_sizes', defaultpageSizeOptions, {})
  const sortByOptions = getSystemSetting('product.available_sorting.ecom', [])
  const productSearchQuery = getSystemSetting('api.v2.skus.index.product_search.ecom.query', {})
  // const fnbEnabled = getSystemSetting('features.fnb.enable')
  const availableFilters = getConfig('config.availableFilters', {
    mobileView: {
      left: ['color', 'size', 'brand', 'category'],
      right: ['sortBy', 'view'],
    },
    desktopView: {
      left: ['color', 'size', 'brand', 'category'],
      right: ['sortBy', 'view'],
    },
  })
  // prepare search parameters
  const [queryTitle, setQueryTitle] = useState(undefined)
  const urlFilterOptions = useMemo(() => {
    let options = {}
    if (isBrowser()) {
      options = getProductListFilterOptionsFromUrl(location.href)
    }

    setQueryTitle(_.get(options, 'q', undefined))
    // if page size and sort by did not exist in url params, use a default value
    const pageSize = _.toInteger(options.pageSize) || _.get(pageSizeOptions, 'default', 8)
    const sortByQuery = options.sortBy
      ? { sortBy: options.sortBy }
      : flow(
        find({ default: true }),
        get('query'),
      )(sortByOptions)
    return {
      ...options,
      ...sortByQuery,
      pageSize,
    }
  }, [location, pageSizeOptions, sortByOptions])

  // internal states
  // const [hasMore, setHasMore] = useState(false)
  // const [brandOptions, setBrandOptions] = useState([])
  const [filterOptions, setFilterOptions] = useState(urlFilterOptions)
  const [filterTypeChanged, setFilterTypeChanged] = useState('')
  const [colorMasterOptions, setColorMasterOptions] = useState([])
  const [pageReady, setPageReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [initLoading, setInitLoading] = useState(true)
  const [sizeMasterOptions, setSizeMasterOptions] = useState([])
  const [loadingNextSizeMaster, setLoadingNextSizeMaster] = useState(false)
  const [fetchNextSizeMasterOptions, setFetchNextSizeMasterOptions] = useState(null)
  const [isNextSizeMasterReady, setIsNextSizeMasterReady] = useState(false)
  const [hasSizeMasterMore, setHasSizeMasterMore] = useState(false)

  const [skus, setSkus] = useState([])
  const [siblings, setSiblings] = useState([])
  const [pagination, setPagination] = useState({})
  const [breadcrumb, setBreadcrumb] = useState([])
  const [titleGroup, setTitleGroup] = useState('')
  const [productImpressions, setProductImpressions] = useState([])
  const [plpContentGroupCode, setPlpContentGroupCode] = useState()
  const [category, setCategory] = useState()
  const [isResetFilterActive, setIsResetFilterActive] = useState(false)
  const [showBreadCrumb, setShowBreadCrumb] = useState(true)

  const [brands, setBrands] = useState([])
  const [loadingBrands, setLoadingBrands] = useState(false)

  const pageTitle = useMemo(() => {
    const breadcrumbLast = flow(last, getOr('', 'text'))(breadcrumb)
    if (!_.isEmpty(queryTitle)) {
      return t('screens.products.searchBy', { query: queryTitle })
    }
    if (!_.isEmpty(titleGroup)) {
      return titleGroup
    }
    if (
      !_.isEmpty(_.get(filterOptions, 'departmentCodeEq'))
      || !_.isEmpty(_.get(filterOptions, 'categoryCodeEq'))
    ) {
      return breadcrumbLast
    }
    // == Multi-brands title
    // if (!_.isEmpty(_.get(filterOptions, 'brandCodeEq'))) {
    //   return _.join(
    //     _.map(
    //       _.filter(
    //         brands,
    //         ({ code }) => (
    //           _.includes(_.castArray(_.get(filterOptions, 'brandCodeEq', [])), code)
    //         ),
    //       ),
    //       'name',
    //     ),
    //     ', ',
    //   )
    // }

    // == Single brand title
    const brandCodes = _.castArray(_.get(filterOptions, 'brandCodeEq', []))
    if (
      !_.isEmpty(brandCodes)
      && _.size(brandCodes) === 1
    ) {
      return _.get(
        _.find(
          brands,
          ({ code }) => (
            _.first(brandCodes) === code
          ),
        ),
        'name',
      )
    }
    return breadcrumbLast
  }, [queryTitle, breadcrumb, titleGroup, filterOptions])

  // SEO Meta
  const seoTitle = useMemo(() => {
    const categoryCodeEq = _.get(filterOptions, 'categoryCodeEq')
    const departmentCodeEq = _.get(filterOptions, 'departmentCodeEq')
    if (!_.isEmpty(categoryCodeEq)) {
      return _.get(_.find(categories, { code: categoryCodeEq }), 'metaTitle')
        || _.get(_.find(categories, { code: categoryCodeEq }), 'name', pageTitle)
    }
    if (!_.isEmpty(departmentCodeEq)) {
      return _.get(_.find(departments, { code: departmentCodeEq }), 'metaTitle')
        || _.get(_.find(departments, { code: departmentCodeEq }), 'name', pageTitle)
    }
    return pageTitle
  }, [filterOptions, departments, categories, pageTitle])
  const seoDescription = useMemo(() => {
    const categoryCodeEq = _.get(filterOptions, 'categoryCodeEq')
    const departmentCodeEq = _.get(filterOptions, 'departmentCodeEq')
    if (!_.isEmpty(categoryCodeEq)) {
      return _.get(_.find(categories, { code: categoryCodeEq }), 'metaDescription', '')
    }
    if (!_.isEmpty(departmentCodeEq)) {
      return _.get(_.find(departments, { code: departmentCodeEq }), 'metaDescription', '')
    }
    return ''
  }, [filterOptions, departments, categories])
  const seoMeta = useMemo(() => {
    const categoryCodeEq = _.get(filterOptions, 'categoryCodeEq')
    const departmentCodeEq = _.get(filterOptions, 'departmentCodeEq')
    const meta = []
    if (!_.isEmpty(categoryCodeEq)) {
      meta.push({
        name: 'keyword',
        content: _.get(_.find(categories, { code: categoryCodeEq }), 'metaKeywords', ''),
      })
    } else if (!_.isEmpty(departmentCodeEq)) {
      meta.push({
        name: 'keyword',
        content: _.get(_.find(departments, { code: departmentCodeEq }), 'metaKeywords', ''),
      })
    }
    return meta
  }, [filterOptions, departments, categories])
  const filterCategories = useMemo(() => {
    const categoryCodeEq = _.get(filterOptions, 'categoryCodeEq')
    const departmentCodeEq = _.get(filterOptions, 'departmentCodeEq')
    let result = []
    if (!_.isEmpty(categoryCodeEq)) {
      const currentCategory = _.find(categories, { code: categoryCodeEq })
      const childIds = _.get(currentCategory, 'childIds', [])
      result = _.filter(categories, ({ id }) => _.includes(childIds, id))
    } else if (!_.isEmpty(departmentCodeEq)) {
      const currentDepartment = _.find(departments, { code: departmentCodeEq })
      const currentDepartmentId = _.get(currentDepartment, 'id', [])
      result = _.filter(categories, { departmentId: currentDepartmentId })
    }
    return result
  }, [filterOptions, departments, categories])

  const distinct = useMemo(() => _.get(productSearchQuery, 'distinct', 'pc'), [productSearchQuery])
  // transform filter options
  const transformedBrandOptions = useMemo(() => (
    transformFilterBrands(brands)
  ), [brands])
  const transformedCategoryOptions = useMemo(() => (
    transformFilterCategories(filterCategories)
  ), [filterCategories])
  const transformedColorMasterOptions = useMemo(() => (
    transformFilterColorOptions(colorMasterOptions)
  ), [colorMasterOptions])
  const transformedPageSizeOptions = useMemo(() => (
    transformFilterPageSize(pageSizeOptions.pageSizes)
  ), [pageSizeOptions.pageSizes])
  const transformedSizeMasterOptions = useMemo(() => (
    transformFilterSizeOptions(sizeMasterOptions)
  ), [sizeMasterOptions])
  const transformedSortByOptions = useMemo(() => (
    transformFilterSortBy(sortByOptions, currentLocale)
  ), [sortByOptions])
  // get filter option placeholder
  const colorMasterOptionsPlaceholder = useMemo(() => {
    const numberOfOptions = transformedColorMasterOptions.length
    const firstOption = _.first(transformedColorMasterOptions) || {}
    return numberOfOptions === 1 && firstOption.id !== 'others'
      ? firstOption.label
      : t('screens.products.color')
  }, [transformedColorMasterOptions])
  const sizeMasterOptionsPlaceholder = useMemo(() => {
    const numberOfOptions = transformedSizeMasterOptions.length
    const firstOption = _.first(transformedSizeMasterOptions) || {}
    return numberOfOptions === 1 && firstOption.id !== 'others'
      ? firstOption.label
      : t('screens.products.size')
  }, [transformedSizeMasterOptions])
  const sortByOptionsPlaceholder = flow(
    find((option) => filterOptions.sortBy === option.value),
    getOr('', 'label'),
  )(transformedSortByOptions)
  const showDineInOrderPlaced = useMemo(() => (
    // totalItems > 0
    // && _.get(orderMethod, 'code') === 'dineIn'
    _.get(orderMethod, 'code') === 'dineIn'
  ), [totalItems, orderMethod])
  const cachedInventoryStoreCode = useMemo(() => {
    if (_.get(orderMethod, 'code', '') === 'dineInMenu') {
      return _.get(store, 'code')
    }
    return inventoryStoreCode
  }, [inventoryStoreCode, orderMethod, store])
  const skuIds = useMemo(() => _.join(_.map(skus, 'id'), ','), [skus])

  const topLevelCategoryCode = useMemo(() => getTopLevelCategoryCode({
    topLevelCategory: _.get(orderMethod, 'topLevelCategory'),
    storeCode: cachedInventoryStoreCode,
  }), [orderMethod, cachedInventoryStoreCode])

  /**
   * fetchSkusApi
   * get sku search result from API
   */
  const fetchSkusApi = useCallback(async () => {
    if (!isLoading) return
    if (isNullOrUndefined(cachedInventoryStoreCode)) return
    try {
      const menuFiltersParam = getMetaMenuFilterParams()
      const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync()
      // set default top level category
      const topCategoryCode = getTopLevelCategoryCode({
        topLevelCategory: _.get(orderMethod, 'topLevelCategory'),
        storeCode: _.get(store, 'code', ''),
      })
      const option = {
        includes: [
          'product',
          'color_option',
          'meta',
          'products.color_option_variant_type',
          'products.category_ids',
          'products.brand_ids',
          'products.meta',
          ...(
            _.isEqual(distinct, 'p') ? ['products.color_option_count'] : []
          ),
        ].join(','),
        distinct,
        inventoryStoreCodeEq: cachedInventoryStoreCode,
        /* [FL] 20210507 Roku: For new format,
         * it should be product_type_eq, but backend not supported yet
         * please use product_types first
         */
        productTypes: 'product',
        categoryCodeEq: topCategoryCode || undefined,
        ...productSearchQuery,
        ...filterOptions,
        ...menuFiltersParam,
        ...menuCodeFiltersParam,
      }

      // call api
      const { skus: skusData, pagination: paginationData } = await fetchSkus(option)
      setSkus((prevSkus) => {
        // In some case, filters changed but reture same result as previous
        //   That make fetch noCached data function not fired
        if (_.isEmpty(prevSkus)) {
          return skusData
        }
        if (
          _.isEmpty(
            _.differenceBy(
              prevSkus,
              skusData,
              'id',
            ),
          )
          && _.size(prevSkus) === _.size(skusData)
        ) {
          // follow new sorting even results are same
          const sortedPrevSkus = _.map(skusData, ({ id }) => _.find(prevSkus, { id }))
          return sortedPrevSkus
        }
        return skusData
      })
      setPagination(paginationData)
    } catch (error) {
      // TODO: handle error
    } finally {
      setIsLoading(false)
      setInitLoading(false)
    }
  }, [isLoading, cachedInventoryStoreCode])

  /**
   * fetchSkusByIdApi
   * get sku by ids for non cached data
   */
  const fetchSkusByIdApi = useCallback(async () => {
    if (isNullOrUndefined(cachedInventoryStoreCode)) return
    if (_.isEmpty(skuIds)) return
    try {
      const option = {
        id: skuIds,
        includes: [
          'color_option',
          'color_options.active_custom_labels',
          'color_options.favourite',
          'color_options.price_details',
          'color_options.stock_level',
          'product',
          'products.stock_level',
        ].join(','),
        inventoryStoreCodeEq: cachedInventoryStoreCode,
        priceStoreCodeEq: cachedInventoryStoreCode,
        productTypes: 'product',
        pageSize: 9999,
        pageCountless: true,
      }

      // call api
      const { skus: skusData } = await fetchSkusAll(option)
      // Combine data
      setSkus((prevSkus) => (
        _.map(prevSkus, (prevSku) => {
          const sku = _.find(skusData, { id: _.get(prevSku, 'id') })
          const detailedColorOption = _.get(sku, 'colorOption', {})
          const productStockLevel = _.get(sku, 'product.stockLevel', null)
          return {
            ...prevSku,
            colorOption: detailedColorOption,
            product: {
              ..._.get(prevSku, 'product', {}),
              stockLevel: productStockLevel,
            },
          }
        })
      ))
    } catch (error) {
      // TODO: handle error
    } finally {
      setPageReady(true)
    }
  }, [cachedInventoryStoreCode, skuIds])

  const fetchSkusByProductCodeApi = useCallback(async (code) => {
    try {
      // api call option
      // setSiblingsLoading(true)
      const option = {
        productCodeEq: code,
        pageCountless: true,
        // schemaVersion: '2021-04-29',
        includes: [
          'skus.meta',
          'skus.product',
          'skus.price_details',
        ].join(','),
      }
      // call api
      const { skus: _siblings } = await fetchSkusAll(option)
      setSiblings((prevSiblings) => _.unionBy(_siblings, prevSiblings, 'id'))
    } catch (error) {
      // fail silently
    } finally {
      // setSiblingsLoading(false)
    }
  }, [fetchSkus])

  /**
   * fetchColorMastersApi
   * get sku search filter color options from API
   */
  const fetchColorMastersApi = useCallback(async () => {
    try {
      // api call option
      // TODO: include other filters
      const option = {
        includes: [
        ].join(','),
        ..._.omit(filterOptions, [
          'colorMasterCodeEq',
          'page',
          'pageSize',
        ]),
      }
      // call api
      const { colorMasters: data } = await fetchColorMasters(option)
      setColorMasterOptions(data)
      // TODO: load next page
    } catch (error) {
      // TODO: handle error
    }
  }, [fetchColorMasters, filterOptions])

  /**
   * fetchSizeMastersApi
   * get sku search filter size options from API
   */
  const fetchSizeMastersApi = useCallback(async (fetchNextPage) => {
    setLoadingNextSizeMaster(true)
    try {
      // api call option
      // TODO: include other filters
      const option = {
        includes: [
        ].join(','),
        ..._.omit(filterOptions, [
          'sizeMasterCodeEq',
          'page',
          'pageSize',
        ]),
      }
      // call api
      const { sizeMasters: data, next } = _.isFunction(fetchNextPage)
        ? await fetchNextPage()
        : await fetchSizeMasters(option)

      setSizeMasterOptions((prevState) => _.uniqBy(prevState.concat(data), 'id'))
      setFetchNextSizeMasterOptions(() => next)
      setHasSizeMasterMore(_.isFunction(next))
      if (_.isEmpty(fetchNextPage)) {
        setIsNextSizeMasterReady(true)
      }
    } catch (error) {
      // TODO: handle error
      console.error('fetchOrders error: ', error)
    } finally {
      setLoadingNextSizeMaster(false)
    }
  }, [fetchSizeMasters, filterOptions])

  /**
   * fetchBrandsApi
   * get sku search filter size options from API
   */
  const fetchBrandsApi = useCallback(async () => {
    setLoadingBrands(true)
    try {
      // api call option
      // TODO: include other filters
      const option = {
        includes: [
        ].join(','),
        ..._.omit(filterOptions, [
          'brandCodeEq',
          'page',
          'pageSize',
          // 'sortBy',
        ]),
        skuActive: true,
      }
      // call api
      const { brands: data } = await fetchBrandsAll(option)

      setBrands(data)
    } catch (error) {
      // TODO: handle error
      console.error('fetchBrands error: ', error)
    } finally {
      setLoadingBrands(false)
    }
  }, [filterOptions])

  function handleFetchSizeMasterNextPage() {
    if (!_.isFunction(fetchNextSizeMasterOptions)) return
    fetchSizeMastersApi(fetchNextSizeMasterOptions)
  }

  /**
   * productGroups, get Title
   * get Categories to build the breadcrumb
   */
  const fetchProductGroupByCodeApi = useCallback(async () => {
    // HOTFIX: put inside onFetchTitleProducts to prevent build error
    try {
      const productGroupCodeEq = _.get(filterOptions, 'productGroupCodeEq')
      // get productGroupId - also look for productGroupIds for backwards compatibility
      const productGroupId = _.get(filterOptions, 'productGroupId') || _.head(_.get(filterOptions, 'productGroupIds[]'))

      let data

      if (!_.isEmpty(productGroupCodeEq)) {
        const { productGroup } = await fetchProductGroupByCode({
          code: productGroupCodeEq,
        })
        data = productGroup
        setTitleGroup(_.get(data, 'title', ''))
      } else if (!_.isEmpty(productGroupId)) {
        const { productGroup } = await fetchProductGroup({
          id: productGroupId,
        })
        data = productGroup
        setTitleGroup(_.get(data, 'title', ''))
      } else {
        setTitleGroup('')
      }
    } catch (error) {
      // TODO: handle error
    }
  }, [fetchProductGroupByCode, filterOptions])

  const updateUrlParams = useCallback((options) => {
    const url = new URI(location.pathname)
    const params = _.mapKeys(options, (v, k) => _.snakeCase(k))
    url.search(params)
    const newUrl = _.replace(url.href(), `/${currentLocale}`, '')
    navigate(newUrl, { replace: true })
  }, [location])

  const cleanUrlParams = useCallback((options) => {
    const url = new URI(location.pathname)
    url.search(options)
    const newUrl = _.replace(url.href(), `/${currentLocale}`, '')
    navigate(newUrl, { replace: true })
  }, [location])

  function handleFilterOptionUpdate(type, value) {
    setPageReady(false)
    let newFilterOption = {}
    if (type === 'pageSize' || type === 'sortBy') {
      newFilterOption = {
        ..._.omit(filterOptions, [
          'page',
        ]),
        [type]: value,
      }
    } else {
      newFilterOption = {
        ...filterOptions,
        page: 1,
        [type]: value,
      }
    }
    setFilterOptions(camelcaseKeys(newFilterOption))
    setFilterTypeChanged(type)
    // update browser url with latest filter option
    updateUrlParams(newFilterOption)
  }

  /**
   * handleBreadcrumb
   * create array for breadcrumb, first use Promise all to get Department and category
   */
  const handleBreadcrumb = useCallback(() => {
    // [TODO] support full category tree like PDP
    const url = new URI(location.href)
    const query = camelcaseKeys(url.query(true))
    const initialBreadcrumb = [
      {
        text: t('screens.products.breadcrumb.all'),
        url: '/products',
      },
    ]
    const queryDepartment = query.departmentCodeEq
    const queryCategory = query.categoryCodeEq
    const isMultiCategory = _.isArray(queryCategory) && _.size(queryCategory) > 1

    // Use Categories & Departments from localStorage
    const topLevelCategory = _.find(categories, { code: topLevelCategoryCode })
    const departmentData = _.get(_.find(departments, { code: queryDepartment }), 'name')
    const categoryData = _.get(_.find(categories, { code: _.first(_.castArray(queryCategory)) }), 'name')
    const firstLevelData = _.isEmpty(topLevelCategory)
      ? {
        text: departmentData,
        url: `/products/?departmentCodeEq=${queryDepartment}`,
      }
      : {
        text: _.get(topLevelCategory, 'name'),
        url: `/products/?categoryCodeEq=${topLevelCategoryCode}`,
      }
    setCategory(_.find(categories, { code: queryCategory }))
    if (queryDepartment) {
      if (queryCategory && !isMultiCategory) {
        initialBreadcrumb.push(
          firstLevelData,
          {
            text: categoryData,
            url: '',
          },
        )
      } else {
        initialBreadcrumb.push({
          text: firstLevelData.text,
          url: '',
        })
      }
      setBreadcrumb(initialBreadcrumb)
    } else if (queryCategory) {
      initialBreadcrumb.push({
        text: categoryData,
        url: '',
      })
      setBreadcrumb(initialBreadcrumb)
    } else {
      setBreadcrumb(initialBreadcrumb)
    }
    return {
      department: firstLevelData.text,
      category: categoryData,
    }
  }, [location.href, departments, categories, topLevelCategoryCode])

  function handleBrandChange(value) {
    handleFilterOptionUpdate('brandCodeEq', _.castArray(value))
  }

  function handleCategoryChange(value) {
    handleFilterOptionUpdate('categoryCodeEq', value)
  }

  function handleColorOptionChange(value) {
    handleFilterOptionUpdate('colorMasterCodeEq', _.castArray(value))
  }

  function handleSizeOptionChange(value) {
    handleFilterOptionUpdate('sizeMasterCodeEq', _.castArray(value))
  }

  function handlePageSizeUpdate(value) {
    handleFilterOptionUpdate('pageSize', value)
  }

  function handleSortByUpdate(value) {
    handleFilterOptionUpdate('sortBy', value)
  }

  function handleResetFilter() {
    // TODO: clear filters
    // use default sort by
    // use default page view
    const categoryCodeEq = _.get(filterOptions, 'categoryCodeEq')
    const departmentCodeEq = _.get(filterOptions, 'departmentCodeEq')
    const productGroupCodeEq = _.get(filterOptions, 'productGroupCodeEq')
    const productGroupId = _.get(filterOptions, 'productGroupId')
    const pageSize = _.get(pageSizeOptions, 'default', 8)
    const sortByQuery = flow(
      filter({ default: true }),
      get('[0]query'),
    )(sortByOptions)
    const updatedCategoryCodeEq = _.isArray(categoryCodeEq) ? topLevelCategoryCode : categoryCodeEq

    setFilterTypeChanged('')
    setFilterOptions({
      ...sortByQuery,
      categoryCodeEq: updatedCategoryCodeEq,
      departmentCodeEq,
      pageSize,
      productGroupCodeEq,
      productGroupId,
    })
    setQueryTitle(undefined)
    cleanUrlParams(_.omitBy({
      categoryCodeEq: updatedCategoryCodeEq,
      departmentCodeEq,
      productGroupCodeEq,
      productGroupId,
      pageSize,
    }, _.isNil))
  }

  const handleFetchFilterOptions = useCallback((excludedFilterType) => {
    const filters = {
      colorMasterCodeEq: fetchColorMastersApi,
      sizeMasterCodeEq: fetchSizeMastersApi,
      brandCodeEq: fetchBrandsApi,
    }
    _.each(filters, (fn, key) => {
      if (excludedFilterType === key) return
      fn()
    })
  }, [fetchBrandsApi, fetchColorMastersApi, fetchSizeMastersApi])

  function handlePageClick(selectedPage) {
    const { currentPage } = pagination
    if (currentPage === selectedPage) return

    handleFilterOptionUpdate('page', selectedPage)
  }

  function handleContinueShopping() {
    const initPage = _.get(orderMethod, 'initPage', '/')
    navigate(initPage)
  }

  function handleAddToCompare(item, isInStore = false) {
    const objProduct = {
      id: _.get(item, 'id', ''),
      productId: isInStore ? _.get(item, 'productId') : _.get(item, 'product.id'),
      image: isInStore ? _.get(item, 'image')
        : _.get(item, 'colorOption.defaultImage.versions.webThumb', ''),
    }
    addItemToCompare(objProduct)
  }

  function trackProductsImpression() {
    const products = _.map(skus, (sku, skuIdx) => ({
      ...getProductParams(sku),
      list: 'Search Results',
      position: (skuIdx + 1),
    }))
    trackEvent('viewProductImpression', {}, { products, title: seoTitle })
    return products
  }

  function handleClickTrackEvent(eventName, product) {
    trackEvent(eventName, {}, { product })
  }

  const onProductClick = ({ productId, colorOptionId }) => {
    openProductQuickAddModal({
      id: productId,
      colorOptionId,
      onAddToCartSuccess: () => {
        alert.show(
          t('screens.products.alertMessage', { context: 'addItemToCartSuccess' }),
          { state: 'default', autoClose: 1000 },
        )
      },
    })
  }

  useEffect(() => {
    fetchSkusApi()
  }, [fetchSkusApi])

  useEffect(() => {
    fetchSkusByIdApi()
  }, [fetchSkusByIdApi])

  useEffect(() => {
    handleBreadcrumb()
  }, [handleBreadcrumb])

  useEffect(() => {
    // FL: use isLoading flag to make sure trigger fetchSkuApi once only
    clearTimeout(fetchSkuApiTimer.current)
    fetchSkuApiTimer.current = setTimeout(() => {
      setIsLoading(true)
    }, 500)
  }, [
    _.get(store, 'id'),
    _.get(orderMethod, 'commerceChannel'),
    _.get(orderMethod, 'deliveryType'),
    _.get(orderMethod, 'commerceType'),
    _.get(orderMethod, 'code'),
  ])
  useEffect(() => {
    setIsLoading(true)
  }, [filterOptions])

  useEffect(() => {
    if (
      _.isEmpty(skus)
      || !pageReady
    ) return
    const pi = trackProductsImpression()
    setProductImpressions(pi)
  }, [skus, pageReady])

  // when filter options is updated, refresh the option
  useEffect(() => {
    handleFetchFilterOptions(filterTypeChanged)
  }, [filterTypeChanged, handleFetchFilterOptions])

  useEffect(() => {
    const searchParams = camelcaseKeys(getProductListFilterOptionsFromUrl(location.href))
    const searchParamsKeys = _.join(_.sortBy(_.keys(searchParams)), '')
    const filterOptionsKeys = _.join(_.sortBy(_.keys(filterOptions)), '')
    const searchParamsValues = _.join(_.sortBy(_.values(searchParams)), '')
    const filterOptionsValues = _.join(_.sortBy(_.map(_.values(filterOptions), _.toString)), '')
    const defaultSortByQuery = camelcaseKeys(
      _.get(
        _.find(sortByOptions, 'default'),
        'query',
        {},
      ),
    )
    if (
      !_.isEqual(searchParamsKeys, filterOptionsKeys)
      || !_.isEqual(searchParamsValues, filterOptionsValues)
    ) {
      setFilterOptions({
        ...searchParams,
        ...(!_.has(searchParams, 'sortBy') ? defaultSortByQuery : {}),
        ...(!_.has(searchParams, 'pageSize') ? { pageSize: _.get(pageSizeOptions, 'default', 24) } : {}),
      })
    }
  }, [location.search])

  useEffect(() => {
    fetchProductGroupByCodeApi()
    if (_.has(filterOptions, 'productGroupCodeEq')) {
      setShowBreadCrumb(false)
    } else {
      setShowBreadCrumb(true)
    }
    const bannerName = getBannerName(filterOptions)
    setPlpContentGroupCode(bannerName)
  }, [filterOptions])

  useEffect(() => {
    const categoryCodeEq = _.get(filterOptions, 'categoryCodeEq')
    if (
      _.has(filterOptions, 'brandCodeEq')
      || _.has(filterOptions, 'colorMasterCodeEq')
      || _.has(filterOptions, 'sizeMasterCodeEq')
      || (
        _.isArray(categoryCodeEq)
        && (
          _.size(categoryCodeEq) > 1 // multi category codes applied
          || !_.includes(categoryCodeEq, topLevelCategoryCode)
        )
      )
    ) {
      setIsResetFilterActive(true)
    } else {
      setIsResetFilterActive(false)
    }
  }, [filterOptions])

  useEffect(() => {
    const siblingsProductCodes = _.uniq(
      _.reject(
        _.compact(_.flatMap(skus, 'product.meta.siblingsProductCodes')),
        (code) => _.includes(_.map(siblings, 'product.code'), code),
      ),
    )
    if (!_.isEmpty(siblingsProductCodes)) {
      fetchSkusByProductCodeApi(siblingsProductCodes)
    }
  }, [skuIds, fetchSkusByProductCodeApi])

  const viewProps = {
    availableFilters,
    brandOptions: transformedBrandOptions,
    cartTotalItemCount: totalItems,
    categoryOptions: transformedCategoryOptions,
    colorMasterOptions: transformedColorMasterOptions,
    colorMasterOptionsPlaceholder,
    currency,
    dineInTableNumber: _.get(initCart, 'meta.dineInTableNumber', ''),
    dineInTableSubNumber: _.get(initCart, 'meta.dineInTableSubNumber', ''),
    distinct,
    filterOptions,
    pageReady,
    initLoading,
    isLoading,
    pagination,
    pageSizeOptions: transformedPageSizeOptions,
    sizeMasterOptions: transformedSizeMasterOptions,
    sizeMasterOptionsPlaceholder,
    skus,
    siblings,
    sortByOptions: transformedSortByOptions,
    sortByOptionsPlaceholder,
    breadcrumb,
    plpContentGroupCode,
    category,
    topLevelCategoryCode,
    isResetFilterActive,
    pageContext,
    showBreadCrumb,
    showDineInOrderPlaced,
    pageTitle,
    productImpressions,
    seoTitle,
    hasMoreThan,
    enableComparisonEcom,
    maxNumberComparisonEcom,
    compareData,
    goToCompareProducts,
    isOpen,
    selectableOrderMethod,
    loadingBrands,
    loadingNextSizeMaster,
    isNextSizeMasterReady,
    hasSizeMasterMore,
    inventoryStoreCode: cachedInventoryStoreCode,
    seoDescription,
    seoMeta,
    onBrandChange: handleBrandChange,
    onCategoryChange: handleCategoryChange,
    onColorOptionChange: handleColorOptionChange,
    onContinueShopping: handleContinueShopping,
    onPageClick: handlePageClick,
    onPageSizeUpdate: handlePageSizeUpdate,
    onProductClick,
    onResetFilter: handleResetFilter,
    onSizeOptionChange: handleSizeOptionChange,
    onSortByUpdate: handleSortByUpdate,
    onAddToCompare: handleAddToCompare,
    onClearCompare: clearItemToCompare,
    onClickTrackEvent: handleClickTrackEvent,
    onFetchSizeMasterNextPage: handleFetchSizeMasterNextPage,
  }

  return (
    <ProductsView {...viewProps} />
  )
}

export default ProductsController
