import { createUseStyles } from 'react-jss'
import hexToRgba from '../../../../helpers/hex-to-rgba'

const useStyles = createUseStyles((theme) => ({
  '@keyframes loading': {
    from: {
      transform: 'translateX(-100%)',
    },
    to: {
      transform: 'translateX(100%)',
    },
  },
  container: {
    borderTop: `1px solid ${theme.colors.gray300}`,
    borderBottom: `1px solid ${theme.colors.gray300}`,
    backgroundColor: theme.colors.background,
    overflow: 'hidden',
  },
  centerContent: {
    maxWidth: `calc(${theme.mediaQueries.desktop}px + 3.2rem)`,
    margin: '0 auto',
    padding: '0 1.6rem 1rem',
  },
  parentCategoryName: {
    fontSize: '1.6rem',
    lineHeight: '1.2em',
    margin: '1.6rem 0 0.5rem 0',
  },
  menuContainer: {
    marginLeft: '-1.2rem',
    marginRight: '-1.6rem',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4rem',
      background: `linear-gradient(90deg, ${hexToRgba(theme.colors.background, 0)} 0%,  ${hexToRgba(theme.colors.background, 1)} 100%)`,
      zIndex: '10',
      pointerEvents: 'none',
    },
    '&:last-child': {
      marginBottom: '-1rem',
    },
  },
  backToCategories: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '1rem',
    color: theme.colors.text,
    textDecoration: 'none',
    padding: '1rem 1rem 0 0',
    fontSize: '1.4rem',
  },
  menu: {
    display: 'flex',
    whiteSpace: 'nowrap',
  },
  menuItem: {
    marginTop: '0.2rem',
    padding: '0 1.2rem ',
    margin: 0,
    fontSize: '1.4rem',
    '&:last-child': {
      paddingRight: '3.5rem',
    },
  },
  menuItemActive: {
    // borderBottom: `0.1rem solid ${theme.colors.text}`,
  },
  menuItemInner: {
    padding: '1.2rem 0',
  },
  menuItemInnerActive: {
    borderBottom: `0.2rem solid ${theme.colors.secondary}`,
  },
  menuContainerLoading: {
    '&::after': {
      pointerEvents: 'none',
      width: 'auto',
      content: '""',
      display: 'block',
      opacity: 0.5,
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      background: `linear-gradient(to right, ${hexToRgba(theme.colors.background, 0)} 0%,${hexToRgba(theme.colors.background, 1)} 50%,${hexToRgba(theme.colors.background, 0)} 100%)`,
      transform: 'translateX(-100%)',
      animationName: '$loading',
      animationDuration: '2s',
      animationTimingFunction: 'ease',
      animationIterationCount: 'infinite',
    },
  },
  menuItemPlaceholderInner: {
    display: 'block',
    width: '10rem',
    height: '2rem',
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: '1.6rem',
    marginBottom: '1.8rem',
    backgroundColor: '#eee',
    borderRadius: '0.5rem',
  },
}))

export default useStyles
